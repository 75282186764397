import React from "react";
import SelectedFileIndicator from "./SelectedFileIndicator";

const FilesList = ({ files, onRemove }) => {
  const maxVisibleFiles = 4; // Adjust based on your space constraints

  return (
    <div className="file-list">
      {files.length > 0 && (
        <>
          {files.slice(0, maxVisibleFiles).map((file, index) => (
            <SelectedFileIndicator
              key={index} // Use index here for key
              fileName={file}
              onRemove={() => onRemove(index)} // Pass the index to onRemove
            />
          ))}
          {files.length > maxVisibleFiles && (
            <span className="more-files">...</span>
          )}
        </>
      )}
    </div>
  );
};

export default FilesList;
