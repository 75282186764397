// src/api/salesTracking.js

import axios from "axios";
import config from "../config";

// Function to send sales data to your backend
export const trackSale = async (transactionData) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/partnero/sales`,
      transactionData
    );
    return response.data;
  } catch (error) {
    console.error("Error sending sales data:", error);
    throw new Error("Failed to send sales data");
  }
};
