import axios from "axios";
import config from "../config";

import AWS from "aws-sdk";

const API_ENDPOINT = `${config.apiBaseUrl}/ai-services`;

// Function to get messages from a specific chat
export const getMessages = async (chatId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/chat/${chatId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching messages for chat ID ${chatId}:`, error);
    throw error; // Re-throw to handle it in calling function if necessary
  }
};

// Function to send a message to a specific chat
export const sendMessage = async (
  userId,
  chatId,
  prompt,
  files,
  chatType,
  isMentor = false
) => {
  try {
    const response = await axios.post(
      `${config.apiBaseUrl}/ai-services/chat/send-attachment`,
      {
        userId: userId,
        chatId: chatId,
        prompt: prompt,
        files: files,
        chatType: chatType,
        isMentor: isMentor,
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error sending message to chat ID ${chatId}:`, error);
    throw error;
  }
};

// Function to fetch chat history for a user
export const getChatHistory = async (userId) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}/user/chats/${userId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching chat history for user ID ${userId}:`, error);
    throw error;
  }
};

// Function to create a new chat
export const createChat = async (userId) => {
  try {
    const response = await axios.post(`${API_ENDPOINT}/create/chat`, {
      userId,
    });
    return response.data;
  } catch (error) {
    console.error(`Error creating a new chat for user ID ${userId}:`, error);
    throw error;
  }
};

// Function to delete a chat
export const deleteChat = async (chatId) => {
  try {
    const response = await axios.delete(`${API_ENDPOINT}/chat/${chatId}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting chat ID ${chatId}:`, error);
    throw error;
  }
};

export const uploadFile = async (file) => {
  const S3_BUCKET = "bevinzey-storage";
  const REGION = "eu-west-3";

  AWS.config.update({
    accessKeyId: "AKIAWBNV3LKWQPMJGBUY",
    secretAccessKey: "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",
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const params = {
    Bucket: S3_BUCKET,
    Key: file.name,
    Body: file,
  };

  try {
    console.log("uploading file");
    await s3.putObject(params).promise();
    const fileLink = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${file.name}`;
    console.log(fileLink);
    console.log("File uploaded successfully.");
    return {
      success: true,
      link: fileLink,
    };
  } catch (error) {
    console.error("Error uploading file:", error);
    return {
      success: false,
    };
  }
};
