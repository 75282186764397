const Dropdown = ({ options, selectedOption, onSelect }) => {
  return (
    <form className="relative z-30 w-40 m-3">
      <select
        id="options"
        value={selectedOption || ""}
        onChange={(e) => onSelect(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-4 py-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      >
        {options.map((option) => (
          <option
            key={option}
            onClick={() => onSelect(option)}
            className="cursor-pointer text-black font-medium px-4 py-2 hover:bg-gray-100"
          >
            {option}
          </option>
        ))}
      </select>
    </form>
  );
};

export default Dropdown;
