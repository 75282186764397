import React, { useEffect, useRef } from "react";
import { TbListDetails } from "react-icons/tb";
import { AiOutlineSend } from "react-icons/ai";
import { AiOutlinePaperClip } from "react-icons/ai";

function MessagesFooter({
  sendMessage,
  setNewMessage,
  newMessage,
  toggleChatList,
  disabled,
  handleFileUpload,
  disableAttachment,
}) {
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleTextareaChange = (e) => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    setNewMessage(e.target.value);
  };

  useEffect(() => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  }, [newMessage]);

  const handleFileChange = (e) => {
    const files = e.target.files;
    const maxFileSize = 100 * 1024 * 1024; // 100MB in bytes
    const maxFiles = 100;

    // Check if the number of files exceeds the limit
    if (files.length > maxFiles) {
      alert(`You can only upload up to ${maxFiles} files.`);
      fileInputRef.current.value = ""; // Clear the input
      return;
    }

    // Check if any file exceeds the size limit
    for (const file of files) {
      if (file.size > maxFileSize) {
        alert("One or more files exceed the 10MB size limit.");
        fileInputRef.current.value = ""; // Clear the input
        return;
      }
    }

    // If all checks pass, proceed with handling the files
    if (files && files.length > 0) {
      console.log("files: ", files);
      handleFileUpload(files);
    }
  };

  return (
    <div className="w-full sticky bottom-0 mt-2">
      <div className="flex items-center justify-between bg-white border-t border-slate-200 px-4 py-2 sm:px-6 md:px-2 max-h-32">
        {/* Message input */}
        <TbListDetails
          onClick={(e) => {
            e.stopPropagation();
            toggleChatList();
          }}
          className="text-black p-2 cursor-pointer hover:bg-gray-100 mr-1 rounded"
          size={45}
        />
        <form
          className="grow flex"
          onSubmit={(e) => {
            e.preventDefault();
            textareaRef.current.style.height = "auto";
            newMessage !== "" && sendMessage(newMessage);
          }}
        >
          <div className="flex items-center justify-center grow mr-3 relative">
            <label htmlFor="message-input" className="sr-only">
              Type a message
            </label>
            <textarea
              disabled={disabled}
              ref={textareaRef}
              className="h-[40px] max-h-[80px] pr-10 w-full resize-none border text-black rounded focus:outline-none scrollbar-w-[3px] scrollbar-thin scrollbar-thumb-[#2e46e8] scrollbar-track-[#6d7eef] overflow-y-scroll scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
              style={{ overflowY: "auto", overflowX: "hidden" }}
              rows="1"
              value={newMessage}
              onChange={(e) => {
                handleTextareaChange(e);
              }}
            ></textarea>
            <button
              disabled={disabled}
              className="text-slate-800 absolute mr-2 right-3 bottom-[50%] translate-y-[35%]"
              type="submit"
            >
              <AiOutlineSend size={20} />
            </button>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            className="hidden"
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
            multiple
          />

          {!disableAttachment && (
            <button
              type="button"
              onClick={() => fileInputRef.current.click()}
              className="text-slate-800 hover:bg-gray-100 p-1 rounded"
            >
              <AiOutlinePaperClip size={25} />
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default MessagesFooter;
